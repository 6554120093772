import React from 'react';
import ReactDOM from 'react-dom';
import Main from "./components/main";
import { HashRouter, Route, Routes } from 'react-router-dom';
import Project from "./components/project";
import SITES from "./data/sites.js";
import "./styles/main.scss";

if(window.location.origin === 'https://www.draconicscales.com') {
  window.location = 'https://projects.draconicscales.com/'
}

const mainComponent = <Main/>;

const main = (
    <HashRouter basename="/">
      <Routes>
        <Route exact path='/' element={mainComponent}/>
        <Route exact path='/about' element={mainComponent}/>
        {SITES.map(({id, path, image, link, title, description}) => (
            <Route
              exact
              key={id}
              path={path}
              element={<Project id={id} image={image} link={link} title={title} description={description} key={id} />}
            />
        ))}
      </Routes>
    </HashRouter>
  );


ReactDOM.render(main, document.getElementById('main'))
