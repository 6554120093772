import React from 'react';
import Nav from "./nav"
import Hero from "./hero";
import Card from "./card";
import Footer from "./footer";
import SITES from "../data/sites.js";

class Main extends React.Component{

    render(){
        return(
            <>
                <Nav />
                <Hero />
                <div className="main-body">
                    <h1>Portfolio</h1>
                    <div className="cards">
                        {SITES.map(({id, path, image, smImage, link, cardTitle, cardText}) => (
                            <Card 
                                image = {image}
                                smImage = {smImage}
                                link = {link}
                                text = {cardText}
                                path = {path}
                                title = {cardTitle}
                                key = {id}
                            />
                        ))}
                    </div>
                    <h1 id="about">About Me</h1>
                    <Footer />
                </div>
            </>
        )
    }
}

export default Main