import React from 'react';
import formatPath from './formatPath';

class Card extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <figure className="card"> 
                <a href={formatPath(this.props.path)} onClick={() => { window.scrollTo(0, 0) }}>
                    <img src={this.props.smImage} alt={this.props.title} className="" />
                </a>
                <p>
                    <a href={formatPath(this.props.path)} onClick={() => { window.scrollTo(0, 0) }}>{this.props.title}</a>
                </p>
                    <figcaption>{this.props.text}</figcaption>
            </figure>
        )
    }
}

export default Card