import React, { Component } from 'react';
import Nav from "./nav"
import OtherWorks from "./other"
class Project extends Component {

  constructor(props) {
    super(props);
    this.createMarkup = this.createMarkup.bind(this);
  }

  render() {
    return (
      <article>
        <Nav />
        <div className="project">
          <h1>{this.props.title}</h1>
          <div className="project-info">
            <p dangerouslySetInnerHTML={this.createMarkup()}></p><br /><br />
          </div>
          <a href={this.props.link} target="_blank">
            <div className="project-image"><img src={this.props.image} alt={this.props.title} /></div>
          </a>
          <OtherWorks id={this.props.id} />
        </div>
      </article>
    );
  };

  createMarkup() {
    return {__html: this.props.description};
  }

};

export default Project;