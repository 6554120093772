import React from 'react';
import image1 from "../images/tree_field.jpg";
import image2 from "../images/linkedin_icon.png";

class Footer extends React.Component{
    render(){
        return(
            <div className="footer">
                <div className="desc">
                    <img src={image1}></img>
                    <div className="text">
                        <h3>Software Developer</h3>
                        <p>
                            My love for things computer-related combined with my love for things artistic led me 
                            to where I am now. Currently, I am working as a developer 
                            doing projects for businesses, friends, and family in different industries and areas 
                            such as healthcare, multimedia, government, and consumer electronics.
                        </p>
                    </div>
                </div>
                <div className="linkedin">
                    <img src={image2}></img>
                    <div className="text">
                        <a href="https://www.linkedin.com/in/kcalpo" target="_blank">View my LinkedIn profile</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer