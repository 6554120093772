import image1 from "../images/qmp.png";
import image2 from "../images/proedge.png";
import image3 from "../images/delta_dental.png";
import image4 from "../images/tivo_business.png";
import image5 from "../images/augmentedjs.png";
import image6 from "../images/github.png";

import image1sm from "../images/qmp_sm.png";
import image2sm from "../images/proedge_sm.png";
import image3sm from "../images/delta_dental_sm.png";
import image4sm from "../images/tivo_business_sm.png";
import image5sm from "../images/augmentedjs_sm.png";
import image6sm from "../images/github_sm.png";

const SITES = [
  {
    id: 0,
    path: '/qmp',
    title: 'QuinStreet Media Platform',
    image: image1,
    smImage: image1sm,
    link: 'https://qmp.ai/#/',
    description:`The QuinStreet Media Platform helps clients identify market segments that 
    they can gather leads and potentially new customers from.<br><br>
    This site is built with VueJS and makes use of such component frameworks as Quasar and 
    chart frameworks such as amCharts. I have helped to maintain the application itself and 
    built out new features on the front-end such as the ability to track client leads.<br><br>`,
    cardTitle: `QuinStreet Media Platform`,
    cardText: `Helps clients manage marketing spend and transparently monetize e-commerce traffic.`
  },
  {
    id: 1,
    path: '/proedge',
    title: 'PwC ProEdge Learn',
    image: image2,
    smImage: image2sm,
    link: 'https://learn.proedge.pwc.com/landing',
    description:`PwC ProEdge Learn is a learning tool built to help employees and PwC's clients upgrade their career path and skills. I worked on the front-end and this tool was 
    primarily built with Vue.js on the front-end. A hybrid approach was used to build for desktop and mobile (Android/iOS) so Ionic and Capacitor were both utilized. 
    CSS frameworks such as Vuetify were also used on the front-end. I used Jest as the test framework to test components on the front-end. Back-end developers built the
    back-end and AI/Machine Learning components in Java.<br><br>`,
    cardTitle: `PwC ProEdge Learn`,
    cardText: `ProEdge Learn is a learning tool built to help people upgrade their career paths and skills.`
  },
  {
    id: 2,
    path: '/deltadentalins',
    title: 'Delta Dental Insurance',
    image: image3,
    smImage: image3sm,
    link: 'https://www.deltadentalins.com/',
    description: `My team was responsible for the redesign of the Delta Dental Insurance enrollment site. As the sole
    front-end developer at the beginning of the project, I created new HTML components based on Atomic Design. These
    components could be used as building blocks, one on top of the other, to create new components. Also, each
    individual component can be re-skinned so that they can be set with different colors and sizes to be
    used with different brands (such as AARP, Costco, etc). I also worked extensively with the front-end
    JavaScript code that is used for the interactivity of the site.<br /><br />`,
    cardTitle: `Delta Dental`,
    cardText: `Delta Dental enrollment site.`
  },
  {
    id: 3,
    path: '/business_tivo',
    title: 'TiVo Business Site',
    image: image4,
    smImage: image4sm,
    link:'https://business.tivo.com',
    description: `This is a site that I worked on with the web team over at TiVo. It is a site that describes different
    products content providers can use to better tailor and customize selections of shows and movies for different customers
    based on preference and individual tastes.<br /><br />Our team made heavy use of HTML5, CSS3, and JavaScript as well as
    preprocessors such as LESS. Front-end developers such as myself and my team lead modified and created the new HTML5, CSS3,
    JavaScript content according to marketing specs and back-end developers implemented our static code in Adobe Experience
    Manager.<br /><br />I had worked on the navigation menu, especially the anchor menu, and set scroll
    values to stop at appropriate places on the page. I also worked on adding a cookie consent plugin to foreign sites that
    implement the new EU cookie law which requires businesses to give users the opportunity to turn cookies on and off. The plugin
    I added was the Cookie Consent Plugin which I tailored to our web code at TiVo. I used the official CSS styles and
    standard HTML code at TiVo to create a cookie consent popup that has the same look and feel as the main TiVo website.`,
    cardTitle: `TiVo Business Site`,
    cardText: `This site describes TiVo's different products and content providers.`
  },
  {
    id: 4,
    path: '/augmentedjs',
    title: 'Augmented.js',
    image: image5,
    smImage: image5sm,
    link: 'http://www.augmentedjs.com/',
    description: `This is a JavaScript framework I am helping to build. It is now on its third iteration. Unlike the first iteration, it
    supports ES6 and better and focuses heavily on component design and ES6 modules. I have helped to build a few of the components as
    well as used the framework in some of my projects.`,
    cardTitle: `Augmented.js`,
    cardText: `This is a JavaScript framework I am helping to build. It is on its third iteration.`
  },
  {
    id: 5,
    path: '/github',
    title: 'Karen\'s GitHub Page',
    image: image6,
    smImage: image6sm,
    link: 'https://github.com/karencalpo',
    description: `This is my personal GitHub page. It contains the code for projects I have worked on in the past as
    well as the present. It also contains code solutions for algorithm problems I solved (all written in JavaScript).<br /><br />I am
    also a member of a collection of ongoing projects started by some very enterprising organizations and individuals.`,
    cardTitle: `Karen\'s GitHub Page`,
    cardText: `This is my personal GitHub page. It contains the code for projects I have worked on.`
  }
];

export default SITES;