import React from 'react';
import SITES from "../data/sites.js";
import MiniCard from "./mini-card";

class OtherWorks extends React.Component {

  constructor(props) {
    super(props);
    this.randomizer = this.randomizer.bind(this);
  }

  render() {
    return (
      <div className="portfolio-items">
        <h2>Other Projects</h2>
        <div className="works">
          {
            (this.randomizer()).map((items) => (
              items
            ))
          }
        </div>
      </div>
    );
  };

  randomizer() {
    let random_sites = [], i;
    for(i = 0; i < SITES.length; i++) {
      random_sites.push(
        <div key = { "minicard" + i }>
            <MiniCard 
                image = {SITES[i].smImage}
                link = {SITES[i].link}
                path = {SITES[i].path}
                title = {SITES[i].cardTitle}
            />
        </div>
      );
    }

    let currentIdx = random_sites.length
    let randomIdx;
    
    while (0 !== currentIdx) {
      randomIdx = Math.floor(Math.random() * currentIdx);
      currentIdx--;

      [random_sites[currentIdx], random_sites[randomIdx]] = [
        random_sites[randomIdx], random_sites[currentIdx]];
    }

    return random_sites.slice(0,4);
  };

};

export default OtherWorks;
