import React from 'react';
import formatPath from './formatPath';

class MiniCard extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <a className="mini-wrap" href={formatPath(this.props.path)} onClick={() => { window.scrollTo(0, 0) }}>
                <figure className="mini-card">
                    <img src={this.props.image} alt={this.props.title} className="" />
                    <p>{this.props.title}</p>
                    <figcaption>{this.props.text}</figcaption>
                </figure>
            </a>
        )
    }
}

export default MiniCard