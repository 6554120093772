import React from 'react';
import image from "../images/tree_field-fair.jpg";

class Hero extends React.Component{
    render(){
        return(
            <div className="hero" />
        )
    }
}

export default Hero