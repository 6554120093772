import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Nav extends React.Component{
    render(){
        return(
            <nav>
                <div>Karen's Web Portfolio</div>
                <ul>
                    <li><Link smooth to="/" className="scroll">Home</Link></li>
                    <li><Link smooth to="/#about" className="scroll">About</Link></li>
                </ul>
            </nav>
        )
    }
}

export default Nav